import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AboutImg1 from "../../assets/images/about-1.png";
import AboutImgApp from "../../assets/images/aboutapp.png";
import AboutImgBrochure from "../../assets/images/aboutbrochure.png";
import AboutImage from "./AboutImage";

const AboutMainContent = () => {
  const { t, i18n } = useTranslation();

  return (
    <Parent>
      <InfoContainer>
        <InfoContent>
          <Caption>Icelandic Coupons</Caption>
          {t("AboutUs")}
        </InfoContent>
        <AboutImage link={AboutImg1} />
      </InfoContainer>

      <InfoContainer style={{ marginTop: "40px" }}>
        <InfoContent>
          <Caption>{t("AboutAppTitle")}</Caption>
          {i18n.language === "en" ? (
            <>
              <p>
                The Icelandic Coupons app is a digital version of the brochure. It updates continuously throughout each
                month, it’s like a fresh coupon buffet at your fingertips.
              </p>
              <p>
                The app contains discounts all over the country at restaurants, bars, cafés, shopping, adventures &
                entertainment in Iceland. Free sample coupons to try out before buying the whole buffet of coupons.
              </p>
              <p>
                The app also has the abilty to find nearby restaurants, bars, cafés & entertainment with just one click.
                Every coupon can be valid for 2-8 persons! And yes, it has 2 for 1 Super Deals!
              </p>
              <p>
                Over a hundred thousand people have downloaded the Icelandic Coupons app and taken advantage of
                discounts all over the country. The app contains various free discounts to try before you buy, but to
                get full access to all discounts, you can purchase a weekend, monthly, or annual pass.
              </p>
            </>
          ) : (
            <>
              <p>
                Icelandic Coupons Appið kom út í janúar 2018 í kjölfar mikillar eftirspurnar eftir því að fá afslættina
                á rafrænt form. Til viðbótar við bæklinginn eru fjöldi afslátta og þeirra á meðal er flokkur sem heitir
                „2 FYRIR 1“ sem inniheldur bestu afslættina fyrir budduna.
              </p>
              <p>
                Vinsældir appsins eru miklar og framar björtustu vonum. Afslættirnir uppfærast í hverjum mánuði, því
                alltaf eitthvað nýtt og spennandi til að prófa.
              </p>
              <p>
                Yfir hundrað þúsund manns hafa sótt sér Icelandic Coupons appið og nýtt sér afslætti um land allt. Appið
                inniheldur ýmissa fría afslætti til að prófa áður en þú kaupir, en til að fá fullan aðgang af öllum
                afsláttum er hægt að kaupa helgar-, mánaðar-, eða ársaðgang.
              </p>
            </>
          )}
        </InfoContent>
        <AboutImage link={AboutImgApp} />
      </InfoContainer>

      <InfoContainer style={{ marginTop: "40px" }}>
        <InfoContent>
          <Caption>{t("AboutBrochureTitle")}</Caption>
          {t("AboutBrochure")}
          {i18n.language === "is" ? (
            <a target="_" href="https://issuu.com/icelandiccoupons/docs/icecoup_innvols_e16_vef2?fr=xKAE9_zU1NQ">
              smelltu hér!
            </a>
          ) : null}
          {i18n.language === "en" ? (
            <a target="_" href="https://issuu.com/icelandiccoupons/docs/icecoup_innvols_e16_vef2?fr=xKAE9_zU1NQ">
              click here!
            </a>
          ) : null}
        </InfoContent>
        <AboutImage link={AboutImgBrochure} />
      </InfoContainer>
    </Parent>
  );
};

const Parent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: 768px) {
    justify-content: space-between;
    max-width: 800px;
  }
`;

const Caption = styled.div`
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const InfoContent = styled.div`
  font-size: 18px;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    max-width: 50%;
  }
`;

export default AboutMainContent;
